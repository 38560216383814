import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist';

import { configureStore } from '@reduxjs/toolkit';
import { nextStep } from './slices/directorSlice';
//import createSagaMiddleware from 'redux-saga';
//import { watcherSaga } from './sagas/rootSaga';
import rootReducer from './slices/rootReducer';
import storage from 'redux-persist/lib/storage';

//const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'lerche',
  version: 1,
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

//sagaMiddleware.run(watcherSaga);
store.dispatch(nextStep({}));