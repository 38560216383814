import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {connectField} from 'uniforms';
import { useTranslation } from "react-i18next";

const Text = ({
    autoComplete,
    disabled,
    id,
    inputRef,
    label,
    name,
    onChange,
    placeholder,
    readOnly,
    type,
    value,
    // eslint-disable-next-line react/prop-types
    componentClassName,
    error,
    //description,
    //fieldInfo,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="field mb-lh">
                <label className="label">
                    {label}</label>
                <div className="control">
                    <input autoComplete={autoComplete}
                        disabled={disabled}
                        id={id}
                        name={name}
                        onChange={
                            event => onChange(event.target.value)
                        }
                        placeholder={placeholder}
                        readOnly={readOnly}
                        ref={inputRef}
                        type={type}
                        value={
                            value ?? ''
                        }
                        className={
                            classNames("input", componentClassName, {
                                invalid: error && props.showInlineError
                            })
                        }/>
                    {/* <span className="icon is-small is-left licon-fingerprint"></span> */}
                </div>
                {
                error && <p className="help is-danger">{t("testcodeField.helpText")}</p>
            } </div>
        </>
    );
};

Text.propTypes = {
    autoComplete: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.any,
    inputRef: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
    inputClassName: PropTypes.string,
    error: PropTypes.any,
    //description: PropTypes.string,
    //fieldInfo: PropTypes.string,
    showInlineError: PropTypes.bool
};

Text.defaultProps = {
    type: 'text',
    value: ''
};

export default connectField(Text, {kind: 'leaf'});
