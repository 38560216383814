import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';

export const DIRECTOR_STEPS = {
    START: 'start',
    TESTING: 'testing',
    FINISHED: 'finished'
};

const initialDirectorSliceState = {
    testName: null,
    testId: null,
    sessionId: null,
    currentStep: DIRECTOR_STEPS.start,
    section: null,
    title: null,
    description: null,
    text: null,
    currentSectionNo: null,
    noOfSections: null,
    currentGroupNo: null,
    noOfGroups: null,
    answeredGroups: [],
    jsonSchema: null,
    readyForNextSection: false,
    textRaed: false,
    resultHtml: false,
    modalContent: null,
    modalActive: false,
    lockIOAction: false,
    submitting: false
};


const doNextStep = (state) => {
    if(state.sessionId) state.currentStep = DIRECTOR_STEPS.TESTING;
};


const directorSlice = createSlice({
    name: 'directorSlice',
    initialState: initialDirectorSliceState,
    reducers: {
        nextStep(state) {
            return doNextStep(state);
        },
        setStoreState(state, action) {
            //log.info("setStoreState", action, state);
            const {testId, sessionId, section} = action.payload;
            state.testId = testId ? testId : state.testId;
            state.sessionId = sessionId ? sessionId : state.sessionId;
            
            if(section) {
                if(section.current_section_number && state.currentSectionNo !== section.current_section_number) {
                    state.textRaed = true;
                    state.readyForNextSection = false;
                    state.title = null;
                    state.description = null;
                    state.text = null;
                }
                if(section.current_group_number && section.current_group_number === section.number_of_groups) state.readyForNextSection = true;
            
                state.currentSectionNo = section.current_section_number ? section.current_section_number : state.currentSectionNo;
                state.noOfSections = section.number_of_sections ? section.number_of_sections : state.noOfSections;
                state.currentGroupNo = section.current_group_number ? section.current_group_number : state.currentGroupNo;
                state.noOfGroups = section.number_of_groups ? section.number_of_groups : state.noOfGroups;
                state.answeredGroups = section.answered_groups ? section.answered_groups : state.answeredGroups;
                if(state.answeredGroups.length !== 0 || state.currentSectionNo === 1) state.textRaed = true;
                state.title = section.title ? section.title : state.title;
                state.description = section.description ? section.description : state.description;
                state.text = section.text ? section.text : state.text;

                state.jsonSchema = section.schema ? section.schema : state.jsonSchema;
            }
            
            doNextStep(state);
            
        },
        textRaed(state) {
            state.textRaed = true;
            
        },
        // eslint-disable-next-line no-unused-vars
        cancelTest: state => initialDirectorSliceState,
        setResults(state, action) {
            const {resultHtml} = action.payload;
            state.currentStep = DIRECTOR_STEPS.FINISHED;
            state.resultHtml = resultHtml;
        },
        setSubmitting(state, action) {
            const {submitting} = action.payload;
            state.submitting = submitting;
        }
    }
});

export const selectDirector = (state) => state.directorReducer;

export const {
    setStoreState,
    nextStep,
    textRaed,
    cancelTest,
    setResults,
    setSubmitting
} = directorSlice.actions;

export default directorSlice.reducer;
