import PlainQandaButton from '../plain/plainQandaButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'uniforms';

const QandaButton = ({
  cb,
  classes,
  iconClasses,
  caption,
  //formRef,
  dir
}) => {

  const {
    error,
    state: { disabled },
    model,
    submitting,
    validating
  } = useForm();

  //console.log("QandaButton", caption, error, disabled, submitting, validating);
   return ( <PlainQandaButton classes={classes} iconClasses={iconClasses} 
    cb={cb.bind(this, model)} dir={dir} caption={caption}
    disabled={!!error || disabled || submitting || validating} />
  );
};

QandaButton.propTypes = {
  cb: PropTypes.func,
  classes: PropTypes.array,
  formRef: PropTypes.func,
  iconClasses: PropTypes.array,
  caption: PropTypes.string,
  dir: PropTypes.string
};

QandaButton.defaultProps = { cb: null, classes: [], iconClasses: [], caption: 'Button caption', dir: "before" };

export default QandaButton;
