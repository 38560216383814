import 'js-snackbar/snackbar.css';
import 'simplebar/dist/simplebar.min.css';
import '/public/css/main.css';

import {I18nextProvider, initReactI18next} from "react-i18next";

import App from './App';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from "react";
import ReactDOM from "react-dom";
import generalDe from "./translations/de/general.json";
//import generalEn from "./translations/en/general.json";
import i18n from "i18next";

//import common_en from "./translations/en/common.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // options see https://github.com/i18next/i18next-browser-languageDetector
  .init({
   resources: {
      de: {
        translation: generalDe
      }
      // en: {
      //   translation: generalEn
      // }
    },
    //lng: "de", // if you're using a language detector, do not define the lng option
    fallbackLng: "de",
    debug: false,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

//console.log("i18n.language", i18n.language);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
        <App/>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);