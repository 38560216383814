{
  "name": "lerche-lesediagnose",
  "version": "1.2.8",
  "browserslist": "> 0.25%, not dead",
  "scripts": {
    "start": "parcel src/index.html",
    "build": "parcel build src/index.html",
    "eslint": "eslint src/ --ext .js --ext .jsx"
  },
  "dependencies": {
    "@material-ui/core": "^4.12.3",
    "@reduxjs/toolkit": "^1.6.1",
    "@types/react": "^16.8.6 || ^17.0.0",
    "ajv": "^8.6.2",
    "ajv-formats": "^2.1.1",
    "classnames": "^2.3.1",
    "html2pdf.js": "^0.9.3",
    "i18next": "^20.6.1",
    "i18next-browser-languagedetector": "^6.1.2",
    "js-snackbar": "^1.1.2",
    "lib": "^4.3.3",
    "logger": "^0.0.1",
    "loglevel": "^1.7.1",
    "loglevel-plugin-remote": "^0.6.8",
    "react": "^16.8.0 || ^17.0.0",
    "react-device-detect": "^2.2.2",
    "react-dom": "^16.8.0 || ^17.0.0",
    "react-helmet-async": "^1.1.0",
    "react-i18next": "^11.12.0",
    "react-idle-timer": "^5.6.2",
    "react-redux": "^7.2.4",
    "react-router-dom": "^5.3.0",
    "react-tooltip": "^4.2.21",
    "redux": "^4.1.1",
    "redux-persist": "^6.0.0",
    "simplebar-react": "^2.3.6",
    "uniforms": "^3.5.5",
    "uniforms-bridge-json-schema": "^3.5.5",
    "uniforms-unstyled": "^3.5.5"
  },
  "devDependencies": {
    "@babel/core": "^7.22.10",
    "@babel/preset-env": "^7.22.10",
    "@babel/preset-react": "^7.14.5",
    "babel-eslint": "^10.1.0",
    "babel-plugin-module-resolver": "^5.0.0",
    "babel-preset-env": "^1.7.0",
    "buffer": "^6.0.3",
    "eslint": "^7.32.0",
    "eslint-plugin-react": "^7.24.0",
    "parcel": "^2.9.3",
    "process": "^0.11.10",
    "serve": "^14.2.0"
  }
}
