import  { Helmet, HelmetProvider } from "react-helmet-async";
import { persistor, store } from './redux/store';

import { ErrorBoundary } from "./lib/logger";
import { Modal } from '/components/modal';
import { NavigationBar } from './components/navigation';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes/routes';
import { Snackbar } from '/components/snackbar';
import { useTranslation } from "react-i18next";
import ReactTooltip from 'react-tooltip';
import {IdleTimer} from '/components/idleTimer';



const App = () => {
    const { t } = useTranslation();

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <HelmetProvider>
                    <Helmet>
                        <title>{t("app.name") + " – " + t("app.subname")}</title>
                        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
                        <meta name="theme-color" content="#E78704" />
                        <meta name="msapplication-navbutton-color" content="#E78704" />
                        <meta name="apple-mobile-web-app-status-bar-style" content="#E78704" />

                        <meta name="author" content="Transporter – Creating Your Web" />
                        <meta name="publisher" content="Transporter – Creating Your Web" />
                        <meta name="copyright" content="Transporter – Creating Your Web" />

                    </Helmet>
                    <Router>
                        <ErrorBoundary>
                            <IdleTimer></IdleTimer>
                            <div className="modals">
                                <Modal />
                            </div>
                            <div className="header" id="header">

                                <NavigationBar />
                            </div>


                            <div className="layout">
                                <Routes />
                            </div>
                            <ReactTooltip type="light" className="tooltip" multiline={true} delayShow={100} delayHide={0} padding="100px 100px"/>
                            <Snackbar />
                        </ErrorBoundary>
                    </Router>

                </HelmetProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
