import React from "react";

const NotFoundPage = () => {

    return (
        <div>
            <p style={{textAlign: "center"}}>
               Page not Found
            </p>
        </div>
    );
};

export default NotFoundPage;
