import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {

    const { t } = useTranslation();


    React.useEffect(() => {

        document.body.classList.add('layout--centered');
        return () => {
            document.body.classList.remove('layout--centered');
        };
    }, []);
    
    return (
        <>
            <div className="section">
                <div className="container is-max-desktop"  dangerouslySetInnerHTML={{__html: t("privacyPoliy.text")}}>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;