import * as ioCalls from '/lib/io';

import { MESSAGE_TYPE, addItem } from '/redux/slices/snackbarSlice';
import { isSafari } from 'react-device-detect';
import PlainQandaButton from '/components/plain/plainQandaButton';
import React from "react";
//import { autoRoute } from "/routes/routes";
import { useParams } from 'react-router-dom';
import { cancelTest } from '/redux/slices/directorSlice';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';
import { selectDirector } from '/redux/slices/directorSlice';
import { setResults } from '/redux/slices/directorSlice';
import { startTestUrl } from '/routes/routesUrls';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import html2pdf from "html2pdf.js";

const testResult = () => {
    let formRef;
    let {type, sessionId } = useParams();
    const directorState = useSelector(selectDirector);
    let history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const onSubmitFailure = (res) => {
        dispatch(addItem({ text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {} }));
    };

    const printResult = () => {
        const el = document.body;
        el.classList.add("layout--result-print");
        const opt = {
            margin: [0, 0, 0, 0],
            filename: "Lerche_Lesetest.pdf",
            image: {
              type: "jpeg",
              quality: 0.98
            },
            html2canvas: {
              height: el.offsetHeight,
              width: el.offsetWidth,
              scale: 2,
              logging: false,
              dpi: 192
            },
            jsPDF: {
              unit: "mm",
              format: "a4",
              orientation: "portrait"
            }
          };
        const clonedElement = el.cloneNode(true);
        el.classList.remove("layout--result-print");
        console.log("xxx", isSafari);
        if(isSafari) html2pdf().set(opt).from(clonedElement).output('dataurlnewwindow', 'Lerche_Lesetest.pdf');
        else html2pdf().set(opt).from(clonedElement).save();
        clonedElement.remove();
    };

    React.useEffect(() => {


        const successF = (res) => {
            //log.info("testResult", "useEffect", res);
            if(res.error) dispatch(addItem({ text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {} }));
            else dispatch(setResults({ resultHtml: res.result_html }));
        };
        let resultRes;
        if(type === "teacher") resultRes = ioCalls.getTeacherResults(directorState.sessionId || sessionId);
        else resultRes = ioCalls.getStudentResults(directorState.sessionId || sessionId);
        resultRes.then(successF, onSubmitFailure);


        document.body.classList.add('layout--centered', 'layout--result');
        return () => {
            document.body.classList.remove('layout--centered', 'layout--result');
        };


    }, []);

    React.useEffect(() => {
        const dlButton = document.querySelector("#dl-button");
        //console.log("xxx directorState.resultHtml", dlButton);
        if(dlButton) dlButton.onclick = printResult;

    }, [directorState.resultHtml]);

    const doCancelTest = async () => {
            dispatch(cancelTest({}));
            dispatch(addItem({ text: t("abortTest.testWasFinished.message"), type: MESSAGE_TYPE.INFO, props: {} }));
            history.push(startTestUrl);
    };

    return (
        <>

            {directorState.resultHtml && 
            <>
            {/* <div className="section">
                <div className="container is-max-tablet" dangerouslySetInnerHTML={{ __html: t("testResultPage.intro") }}>

                </div>
            </div> */}
            <div dangerouslySetInnerHTML={{ __html: directorState.resultHtml }}>
            </div>
            <div className="section section-result-controls">
                <div className="container is-max-tablet">
                    <div className="controls">

                        {!sessionId && <PlainQandaButton cb={printResult}
                            formRef={formRef} classes={["button--direction-down", "is-rounded", "is-success"]}
                            iconClasses={["icon licon-cloud-arrow-down"]} caption={t("testResultPage.pdfButton.caption")} />}
                        {" "}
                        {/* {<PlainQandaButton cb={()=>{window.print();}}
                            formRef={formRef} classes={["button--direction-up", "is-rounded", "is-info"]}
                            iconClasses={["icon licon-printer"]} caption={t("testResultPage.printButton.caption")} />}
                        {" "} */}
                        {!sessionId && <PlainQandaButton cb={doCancelTest} dir="after"
                            formRef={formRef} classes={["button--direction-right", "is-rounded", "is-text"]}
                            iconClasses={["icon licon-arrow-arc-right"]} caption={t("testResultPage.newTestButton.caption")} />}
                    </div>
                </div>
            </div>
            </>
            }
        </>
    );
};

export default testResult;
