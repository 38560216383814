import PropTypes from "prop-types";
import React from 'react';
import classNames from 'classnames';
import {filterDOMProps} from "uniforms";

const ParentField = ({
    //id,
    label,
    description,
    active,
    // eslint-disable-next-line no-unused-vars
    fieldInfo,
    children,
    cardClassName,
    ...props
}) => {
    //console.log("ParentField", cardClassName);
    return (
        <div {...filterDOMProps(props)} key={label}
            className={
                classNames(cardClassName, "qanda__form", {"qanda__form--active": active})
        }>
            {
            label && <div className={classNames("qanda__question", {"qanda__question--active": active})}>
                <p className="qanda__question__text">
                    {label} </p>
                {description && <p className="qanda__question__subtext is-info">
					{description}
				</p>}
            </div>
        }
            {children} 
        </div>
    );
};

ParentField.propTypes = {
    id: PropTypes.any,
    label: PropTypes.string,
    description: PropTypes.string,
    fieldInfo: PropTypes.string,
    cardClassName: PropTypes.string,
    children: PropTypes.any,
    active: PropTypes.bool
};

export default ParentField;
