import ParentField from "./parentField";
import PropTypes from "prop-types";
import React from "react";
import classNames from 'classnames';
import {connectField} from "uniforms";
import {toggleArrayItem} from '/lib/helper';

const CheckboxSelectField = ({
    //allowedValues,
    ///checkboxes,
    disabled,
    componentClasses,
    //cardClassName,
    onChange,
    description,
    fieldType,
    id,
    //inputRef,
    fieldInfo,
    label,
    readOnly,
    //required,
    disableItem,
    options,
    value
    //...props
}) => {

  //console.log("CheckboxSelectField yy01", value, fieldType);

    const multiple = fieldType === Array;

    const handleClick = (toggleValue) => {

        //console.log("handleClick yy011", value, toggleValue);
        if(readOnly) return;
        let newValue;
        if(multiple) {
          newValue = toggleArrayItem(value.slice(), toggleValue);
        } else newValue = toggleValue;
        

        //console.log("handleClick", newValue, toggleValue);
        onChange(newValue);
    };

    const renderElements = () => {
        //console.log("checkboxSelectFiled->render yy01", value, disabled, disableItem, options);
        return options.map(element => {
          
          if(!disabled || !disableItem.includes(element.value)) {
            return <CheckboxSelectFieldElement key={
                element.value
            }
            onClick={handleClick}
            active={
              multiple ? value.includes(element.value) : value === element.value
            }
            multiple={multiple}
            {...element}
            />;
          }
          
        });
    };

    //console.log("checkboxSelectField", componentClasses);
    return (
        <ParentField id={id}
            label={label}
            description={description}
            active={multiple ? value.length > 0 : value !== "" && value !== undefined}
            fieldInfo={fieldInfo}
            cardClassName = {(multiple ? "qanda__checks qanda__form " : " qanda__radio qanda__form ") + ' ' + componentClasses} 
            >
            <div className="qanda__answers">
                    {
                        renderElements()
                    } 
            </div>
        </ParentField>
    );
};

CheckboxSelectField.propTypes = {
    id: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.any,
    error: PropTypes.any,
    description: PropTypes.string,
    fieldInfo: PropTypes.string,
    allowedValues: PropTypes.array,
    disableItem: PropTypes.array,
    checkboxes: PropTypes.string,
    disabled: PropTypes.bool,
    fieldType: PropTypes.func,
    inputRef: PropTypes.string,
    componentClasses: PropTypes.string,
    cardClassName: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.any,
    readOnly: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    }))
};

const CheckboxSelectFieldElement = ({title, value, label, active, multiple, onClick}) => {
    return (

        <label className={classNames({"radio qanda__checkradio": !multiple}, 
            {"checkbox qanda__checkradio": multiple}, 
            {"qanda__checkradio--active": active})}
        >
            <input onChange={() => onClick(value)} className="qanda__checkradio__input" checked={active} type={multiple ? "checkbox" : "radio"} name={title} />
            <div className="qanda__checkradio__box">
                <span className="icon licon-check"></span>
            </div>
            <span className="qanda__checkradio__text">{label}</span>
        </label>

    );
};

CheckboxSelectFieldElement.propTypes = {
    value: PropTypes.any,
    title: PropTypes.string,
    label: PropTypes.string,
    active: PropTypes.bool,
    multiple: PropTypes.bool,
    onClick: PropTypes.func
};

export default connectField(CheckboxSelectField, {kind: "leaf"});
