import * as ioCalls from '/lib/io';

import { MESSAGE_TYPE, addItem } from '/redux/slices/snackbarSlice';
import { cancelTest, selectDirector } from '/redux/slices/directorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import React from "react";
import { deactivateModal } from '/redux/slices/modalSlice';
import {useTranslation} from "react-i18next";
import { homeUrl } from "/routes/routesUrls";
const Component = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const directorState = useSelector(selectDirector);

    const removeModal = () => {
        dispatch(deactivateModal({}));
    };

    const doCancelTest = async () => {

        const res = await ioCalls.abortTest(directorState.sessionId);

        if (!res.error) {
            dispatch(cancelTest({}));
            dispatch(deactivateModal({}));
            dispatch(addItem({text: t("abortTest.testWasAborted.message"), type: MESSAGE_TYPE.INFO, props: {}}));
            history.push(homeUrl);
        } else dispatch(addItem({text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {}}));

    };
    //Todo: translate
    return (
        <>
            <header className="modal-card-head">
                <p className="modal-card-title">{t("modal.abortTest.caption")}</p>
            </header>

            <section className="modal-card-body">
                <p className="has-text-danger has-text-weight-bold">
                {t("modal.abortTest.content.init")}
                </p>
                <p>
                {t("modal.abortTest.content")}
                </p>
            </section>

            <footer className="modal-card-foot">
                <button onClick={doCancelTest} className="button button--direction-down is-danger is-rounded">
                    <span className="icon licon-trash"></span>
                    <span className="button__text">{t("modal.abortTest.submitButton.caption")}</span>
                </button>
                <button  onClick={removeModal} className="button button--direction-left is-text is-rounded">
                    <span className="icon licon-arrow-arc-left"></span>
                    <span className="button__text">{t("modal.abortTest.cancelButton.caption")}</span>
                </button>
            </footer>
        </>
    );
};

export const AbortTest = Component;