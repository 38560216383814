import PropTypes from "prop-types";
import React, { useRef } from "react";
//import '/lib/scrollbot.js';
import SimpleBar from 'simplebar-react';

//import {useTranslation} from "react-i18next";

const ScrollArea = ({children}) => {

    //console.log("ScrollArea", noOfGroups, currentGroupNo, answeredGroups);
    const scroller = useRef(null);
    
    return (
        <SimpleBar key="scroller" ref={scroller} id="story__scrolling" className="story__scrolling">
            {children}
        </SimpleBar>
    );
};

ScrollArea.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default ScrollArea;
