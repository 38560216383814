import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';

const initialModalSliceState = {
    modalContent: null,
    modalActive: false,
    modalHidden: false,
    modalPayload: null
};


const modalSlice = createSlice({
    name: 'modalSlice',
    initialState: initialModalSliceState,
    reducers: {
        activateModal(state, action) {
            const {content, model} = action.payload;
            //console.log("activateModal", action.payload, content);
            state.modalActive = true;
            state.modalHidden = false;
            state.modalContent = content;
            if(model) state.modalPayload = {model};
        
        },
        deactivateModal(state) {
            //console.log("deactivateModal");
            state.modalActive = false;
            state.modalPayload = null;
        },
        hideModal(state) {
            //console.log("deactivateModal");
            state.modalHidden = true;
        },
        unhideModal(state) {
            //console.log("deactivateModal");
            state.modalHidden = false;
        }

    }
});

export const selectModal = (state) => state.modalReducer;

export const {
    activateModal,
    deactivateModal,
    hideModal,
    unhideModal
} = modalSlice.actions;

export default modalSlice.reducer;
