import Ajv from 'ajv';
import CheckboxSelectField from '../../components/forms/checkboxSelectField';
import InputField from '/components/forms/inputField';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import TextcodeField from '/components/forms/testcodeField';
import YearMonthField from '/components/forms/yearMonthField';
import addFormats from "ajv-formats";

const ajv = new Ajv({ allErrors: true, useDefaults: true });
addFormats(ajv);
ajv.addVocabulary(["uniforms", "component", "componentClassName", "options", "label", "value", "fieldType"]);

export const createValidator = (schema) => {
    
    const validator = ajv.compile(schema);
//console.log("createValidator", validator);
    return (model) => {
        validator(model);
        return validator.errors?.length ? { details: validator.errors } : null;
    };
};

export const createUniformSchema = (schemaDefinition, customValidator) => {
    //console.log("createUniformSchema", schemaDefinition, customValidator);
    return new JSONSchemaBridge(schemaDefinition, customValidator ?  customValidator : createValidator(schemaDefinition));

};

const fixUniformsType = (item, id) => {
    let fixedItem = Object.assign({}, item);
    //console.log("fixUniformsType", item);
    let mainSettings = {};
    let uniformSettings = {};
    switch(item.fieldType) {
        case "MULTIPLE_CHOICE":
            uniformSettings.component = CheckboxSelectField;
            mainSettings.items = {type: "string"};
            break;
        case "SINGLE_CHOICE":
            uniformSettings.component = CheckboxSelectField;
            break;
        case "INPUT_FIELD":
            uniformSettings.component = InputField;
            break;
        case "TESTCODE_FIELD":
            uniformSettings.component = TextcodeField;
            break;
        case "YEAR_MONTH":
            uniformSettings.component = YearMonthField;
            break;
        default:
    }
    //uniformSettings.id = id;
    uniformSettings.cardClassName = "";
    
    fixedItem = Object.assign(fixedItem, mainSettings);
    //fieldType is used bei uniform, therefor it needs to be removed here
    delete fixedItem.fieldType;
    fixedItem.uniforms = Object.assign(fixedItem.uniforms || {}, uniformSettings);
    //console.log("fixUniformsType fixedItem", fixedItem);
    return fixedItem;
};


export const setupSchemaFromJson = (jsonSchema, validator = null) => {
    let fixedSchema = JSON.parse(jsonSchema);
    let requiredFields = [];

    //console.log("fixSchema #1", fixedSchema.properties);
    delete fixedSchema.$schema;
    //console.log("fixSchema #1.1", JSON.stringify(fixedSchema));
    Object.keys(fixedSchema.properties).forEach(function(prop) {
        //console.log("fixSchema #2", prop, fixedSchema.properties[prop]);
        if(fixedSchema.properties[prop]?.required) requiredFields.push(prop);
        fixedSchema.properties[prop] = fixUniformsType(fixedSchema.properties[prop], prop);
        
    });
    if(requiredFields.length > 0) fixedSchema.required = requiredFields;
    //console.log("fixSchema #3", fixedSchema);
    return createUniformSchema(fixedSchema, validator);
};


