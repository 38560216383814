import { combineReducers } from 'redux';
import directorSlice from './directorSlice';
import modalSlice from './modalSlice';
import snackbarSlice from './snackbarSlice';
import idleTimerSlice from './idleTimerSlice';

const rootReducer = combineReducers({
    directorReducer: directorSlice,
    modalReducer: modalSlice,
    snackbarReducer: snackbarSlice,
    idleTimerReducer: idleTimerSlice
});

export default rootReducer;