import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'uniforms';

const SubmitButton = ({cb, caption, buttonClasses}) => {
  const {
    error,
    state: { disabled },
    submitting,
    model,
    validating
  } = useForm();

  return (
    <button 
      disabled={!!error || disabled || submitting || validating}
      onClick={cb && cb.bind(this, model)}
      className={buttonClasses}
    >{caption || "Sent"}</button>
  );
};


SubmitButton.propTypes = {
  cb: PropTypes.func,
  caption: PropTypes.object,
  buttonClasses: PropTypes.string
};

export default SubmitButton;