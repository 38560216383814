import PropTypes from "prop-types";
import React from "react";
import classNames from 'classnames';
import {useTranslation} from "react-i18next";

const SectionProgress = ({noOfSections, currentSectionNo}) => {
    const sectionsCaptions = [
        "Meta",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G"
    ];
    const sectionsCaptionsLastSection = "Result";

    const { t } = useTranslation();
    const renderElements = () => {

        let items = [];

        for (let i = 0; i < noOfSections; i++) {
            let classes = ["progress-step" ];
            if(currentSectionNo - 1 > i) classes.push("progress--done");
            if(currentSectionNo - 1 === i) classes.push("progress--active");
            let title =  t("sectionProgress.caption") + " " + sectionsCaptions[i];
            if (i + 1 === noOfSections) title = t("sectionProgress.result.caption");
            if (i === 0) title = t("sectionProgress.first.caption");

            items.push(
                <span  key={i} className={classNames(classes)} data-tip={title}>
                    <span className="progress-bird"></span>
                </span>
            );
        }
        return items;
    };

    return (

			<div className="qanda__progress">		
				<span className="progress-caption">
					Los
				</span>
				<div className="progress-steps">

                {renderElements()}

				</div>
				<span className="progress-caption">
					Fertig
				</span>

			</div>

    );
};

SectionProgress.propTypes = {
    noOfSections: PropTypes.number,
    currentSectionNo: PropTypes.number
};

export default SectionProgress;
