import React, { Component } from 'react';

import {Error} from '/components/error';
import PropTypes from 'prop-types';
import log from 'loglevel';

//import remote from 'loglevel-plugin-remote';


export const customJSON = log => ({
    msg: log.message,
    level: log.level.label,
    stacktrace: log.stacktrace
});


//remote.apply(log, { format: customJSON, url: '/logger' });
log.enableAll();

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };

    }

    // eslint-disable-next-line no-unused-vars
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        
        return { error: error };
    }

    componentDidCatch(error, info) {
        // log the error to our server with loglevel
        log.error({ error, info });
    }

    render() {
        if (this.state.error) return <Error error={this.state.error} />;
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.array
};


export default log;