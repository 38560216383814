import PropTypes from 'prop-types';
import React from 'react';

const PlainQandaButton = ({
  cb,
  classes,
  iconClasses,
  caption,
  disabled,
  dir
}) => {


  //console.log("QandaButton", submitting, validating, error, formRef);

  const buttonClasses = ["button", "is-rounded", ...classes];
  const iClasses = ["icon", ...iconClasses];

   return (
    <button key={caption} className={buttonClasses.join(' ')} onClick={cb} disabled={disabled}>
      {dir === "before" && <span className={iClasses.join(' ')}></span>}
      <span className="button__text" >{caption}</span>
      {dir === "after" && <span className={iClasses.join(' ')}></span>}
    </button>
  );
};

PlainQandaButton.propTypes = {
  cb: PropTypes.func,
  classes: PropTypes.array,
  iconClasses: PropTypes.array,
  caption: PropTypes.string,
  dir: PropTypes.string,
  disabled: PropTypes.bool
};

PlainQandaButton.defaultProps = { cb: null, classes: [], iconClasses: [], caption: 'Button caption', dir: "before", disabled: false };

export default PlainQandaButton;
