import { MESSAGE_TYPE, addItem } from '/redux/slices/snackbarSlice';
import { impressUrl, privacyPolicyUrl, startTestUrl, homeUrl, testResultUrl, testUrl } from '../routes/routesUrls';
import { useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import React from 'react';
import { activateModal } from '/redux/slices/modalSlice';
import { cancelTest } from '/redux/slices/directorSlice';

import {selectIdleState} from '/redux/slices/idleTimerSlice';
import { useSelector} from 'react-redux';
import classNames from 'classnames';
//import log from 'loglevel';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
const pjson = require('../../package.json'); 

// eslint-disable-next-line no-undef
const APP_VERSION = pjson.version;

const NavigationBarComponent = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const idleState = useSelector(selectIdleState);
    const [navIsActive, setNavIsActive] = React.useState(false);
    //console.log("NavigationBarComponent", history.location.pathname, testUrl);
    const goto = (target) => history.push(target);

    const location = useLocation();
    React.useEffect(() => {
        if(idleState.timeLeft > 0) document.body.classList.add("is-timeout");
        else document.body.classList.remove("is-timeout");
    }, [location, idleState.timeLeft]);

    const doCancelTest = async () => {
        if([testResultUrl].includes(history.location.pathname)) {
            dispatch(cancelTest({}));
            // dispatch(addItem({text: t("abortTest.testWasAborted.message"), type: MESSAGE_TYPE.INFO, props: {}}));
            goto(homeUrl);
        } else dispatch(activateModal({content: "AbortTest"}));
    };

    //console.log("NavigationBarComponent", idleState.timeLeft);
    return (
        <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">

            <div className="navbar-brand">

                <div className="navbar-item">
                    <div className="header__logo">
                        <p className=""><span className="icon licon-bird"></span></p>
                    </div>
                    <div className="header__title">
                        <p className="">
                            <span className="header__name">
                                {t("app.name")}
                            </span>
                            <span className="header__subname">
                                {t("app.subname")}
                            </span>
                            <span className="header__version">
                                {APP_VERSION}
                            </span>
                        </p>
                    </div>
                    {idleState.timeLeft > 0 && <div className="header__timeout">
                        <p>
                        {t("navigation.logout.message1")} {Math.round(idleState.timeLeft / 1000)} {t("navigation.logout.message2")}
                        </p>
                    </div>}
                </div>

                <a role="button" onClick={()=>setNavIsActive(!navIsActive)} className={classNames("navbar-burger", {"is-active": navIsActive})} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>

            </div>
            <div className={classNames("navbar-menu", {"is-active": navIsActive})}>
                <div className="navbar-start">
                </div>
                <div className="navbar-end">

                    <div className="navbar-item">
                        <div className="buttons">
                            {![startTestUrl, testUrl, testResultUrl].includes(history.location.pathname) && <button onClick={goto.bind(this, testUrl)} className="button is-small button--direction-right is-rounded is-primary header__launch__start header__launch__start--hidden">
                                <span className="button__text">{t("navigation.startTest.caption")}</span>
                                <span className="icon licon-play-circle"></span>
                            </button>}
                            {[testUrl, testResultUrl].includes(history.location.pathname) && <button onClick={doCancelTest} className="button is-small is-rounded is-text header__launch__stop 0header__launch__stop--hidden">
                                <span className="button__text">{t([testResultUrl].includes(history.location.pathname) ? "testResultPage.newTestButton.caption" : "navigation.cancelTest.caption")}</span>
                                <span className="icon licon-x"></span>
                            </button>}
                        </div>
                    </div>

                    
                        <div className="navbar-item is-hoverable">
                        <a className="navbar-link is-arrowless button is-small is-rounded is-text">
                            <span className="navbar-item__text">{t("navigation.more.caption")}</span>
                            <span className="icon licon-list"></span>
                        </a>
                        <div className="navbar-dropdown is-right is-boxed">
                            <a onClick={goto.bind(this, homeUrl)} className="navbar-item">
                                <span className="navbar-item__text">{t("navigation.home.caption")}</span>
                            </a>
                            {history.location.pathname !== testUrl && <a onClick={goto.bind(this, startTestUrl)} className="navbar-item">
                                <span className="navbar-item__text">{t("navigation.startTest.caption")}</span>
                            </a>}
                            <a onClick={goto.bind(this, privacyPolicyUrl)} className="navbar-item">
                                <span className="navbar-item__text">{t("navigation.privacyPolicy.caption")}</span>
                            </a>
                            <a onClick={goto.bind(this, impressUrl)} className="navbar-item">
                                <span className="navbar-item__text">{t("navigation.impress.caption")}</span>
                            </a>
                        </div>
                    </div>

                    {/* <a onClick={()=>{dispatch(activateModal({content: "Help"}));}} className="navbar-item has-text-info">
                        <span className="navbar-item__text">{t("navigation.help.caption")}</span>
                        <span className="icon licon-question"></span>
                    </a> */}

                </div>
            </div>

        </nav>
    );
};


NavigationBarComponent.propTypes = {
    theme: PropTypes.object
};

export const NavigationBar = NavigationBarComponent;


