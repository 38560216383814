import * as ioCalls from '/lib/io';

import { AutoFields, AutoForm } from "uniforms-unstyled";
import { MESSAGE_TYPE, addItem } from '/redux/slices/snackbarSlice';

import React from "react";
import SubmitButton from "/components/forms/submitButton";
import { autoRoute } from "/routes/routes";
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';
import { selectDirector } from '/redux/slices/directorSlice';
import { setStoreState } from '/redux/slices/directorSlice';
import { startTestSchema } from '/modules/uniforms/schemas';
//import {currentSection} from '../../redux/slices/dataSlice';
// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const StartTest = () => {
    // eslint-disable-next-line no-unused-vars
    let formRef;

    const directorState = useSelector(selectDirector);
    let history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    React.useEffect(() => {

        document.body.classList.add('layout--centered');
        return () => {
            document.body.classList.remove('layout--centered');
        };
    }, []);

    React.useEffect(autoRoute(directorState, history), [directorState]);

    const handleSubmit = async ({ testOrSessionId }) => {
        //log.info("xxx", testOrSessionId);

        if (testOrSessionId && testOrSessionId.length === 8) {
            // todo: replace get current group
            //
            const res = await ioCalls.resumeTest(testOrSessionId);
            //log.info("StartTest", "handleSubmit", res.error);
            if (!res.error) {
                dispatch(setStoreState({ sessionId: testOrSessionId, section: res }));
                dispatch(addItem({text: t("startTestPage.message.testResumed"), type: MESSAGE_TYPE.SUCCESS, props: {}}));
            } else dispatch(addItem({text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {}}));
        } else if (testOrSessionId && testOrSessionId.length === 6) {
            const res = await ioCalls.startTest(testOrSessionId);
            //log.info("StartTest", "handleSubmit", testOrSessionId, res);
            if (!res.error) {
                dispatch(setStoreState({ sessionId: res.session_id, section: res.section }));
                dispatch(addItem({text: t("startTestPage.message.testStarted"), type: MESSAGE_TYPE.SUCCESS, props: {}}));
            } else dispatch(addItem({text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {}}));

        } else dispatch(addItem({text: t('startTestPage.error.testcode'), type: MESSAGE_TYPE.ERROR, props: {}}));
    };

    const getSubmitCaption = () => {
        return (<><span className="button__text">{t("startTestPage.submitButton.caption")}</span>
        <span className="icon licon-arrow-circle-right"></span></>);
    };

    return (
        <>
            <div className="section">

                <div className="container is-max-tablet"  dangerouslySetInnerHTML={{__html: t("startTestPage.intro1")}}>
                </div>
            </div>


            <div className="section bg-primary-gradient-soft">
                <div className="container is-max-tablet">
                    <span dangerouslySetInnerHTML={{__html: t("startTestPage.intro2")}}></span>
                    <div className="box my-lh">
                        <h2 className="is-success">{t("startTestPage.inputNumber.caption")}</h2>
                        <p className="mb-lh">{t("startTestPage.inputNumber.text")}</p>
                        <AutoForm
                            className=""
                            schema={startTestSchema}
                            placeholder
                            onSubmit={handleSubmit}
                            //validate="onChange"
                            //onChangeModel={model => setCurrentLayer(() => model.backgroundLayer)}
                            ref={ref => (formRef = ref)}
                        >
                            <fieldset>
                                <AutoFields />
                                <div className="field mb-lh">
                                <SubmitButton buttonClasses="button is-success button--direction-right is-rounded" caption={getSubmitCaption()}/>
                                </div>
                            </fieldset>
                        </AutoForm>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="container is-max-tablet">
                    <div className="controls">
                        <button onClick={history.goBack} className="button is-text is-rounded">
                            <span className="icon licon-arrow-arc-left"></span>
                            <span className="button__text">Zurück</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StartTest;
