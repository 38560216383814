import {TIMEOUT, removeItem, selectSnackbar} from '/redux/slices/snackbarSlice';
import {useDispatch, useSelector} from 'react-redux';

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';

export const MESSAGE_TYPE_CLASS = {
    ERROR: "is-danger",
    INFO: "",
    SUCCESS: "is-success"
};

const SnackbarComponent = () => {
    const snackbarState = useSelector(selectSnackbar);
    const dispatch = useDispatch();
    let shownSnacks = new Set();

    React.useEffect(() => { // console.log("SnackbarComponent, useEffect", snackbarState.items);

    }, [snackbarState.items]);

    const getSnackbars = () => {

        let bars = [];
        snackbarState.items.forEach(aBar => {
            if (! shownSnacks.has(aBar.id)) {
                setTimeout(() => {
                    // console.log("remove id", shownSnacks, aBar.id);
                    shownSnacks.delete(aBar.id);
                    dispatch(removeItem({id: aBar.id}));
                }, TIMEOUT);
            }
            
            bars.push(
                <SnackbarItemComponent key={
                        aBar.id
                    }
                    id={
                        aBar.id
                    }
                    classname={
                        MESSAGE_TYPE_CLASS[aBar.type]
                    }
                    text={
                        aBar.text
                    }/>
            );
            shownSnacks.add(aBar.id);
        });
        return bars;

    };

    return (
        <div className={
            classNames("snackbars")
        }>
            {
            getSnackbars()
        } </div>
    );
};


const SnackbarItemComponent = ({id, classname, text}) => {


    return (
        <div key={id}
            className={
                "snackbar notification " + classname
        }>
            <p onClick={
                () => {
                    dispatch(removeItem({id}));
                }
            }>
                {text} </p>
        </div>
    );

};


SnackbarItemComponent.propTypes = {
    id: PropTypes.any,
    classname: PropTypes.string,
    text: PropTypes.string
};

export const Snackbar = SnackbarComponent;
