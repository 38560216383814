import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';

const initialIdleTimerSliceState = {
    idleState: "active", // prompted, idle,
    timeLeft: 0
};


const idleTimerSlice = createSlice({
    name: 'idleTimerSlice',
    initialState: initialIdleTimerSliceState,
    reducers: {
        changeState(state, action) {
            //console.log("idleTimerSlice->changeState", action.payload); 
            const {idleState} = action.payload;
            state.idleState = idleState;
            if(["active", "idle"].includes(idleState)) {
                //console.log("idleTimerSlice->changeState #2"); 
                state.timeLeft = 0;
            }
        },
        setTimeleft(state, action) { 
            const {timeLeft} = action.payload;
            //console.log("idleTimerSlice->changeState", action.payload, timeLeft);
            state.timeLeft = timeLeft;
        }
    }
});

export const selectIdleState = (state) => state.idleTimerReducer;

export const {
    changeState,
    setTimeleft
} = idleTimerSlice.actions;

export default idleTimerSlice.reducer;
