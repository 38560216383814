import {selectIdleState, changeState, setTimeleft} from '/redux/slices/idleTimerSlice';
import {cancelTest} from '/redux/slices/directorSlice';
import { useSelector} from 'react-redux';
import { useDispatch } from 'react-redux';
import React from 'react';
//import log from 'loglevel';
import { useIdleTimer, workerTimers } from 'react-idle-timer/dist/index.esm';
import { homeUrl, startTestUrl } from "/routes/routesUrls";
import { useHistory, useLocation } from "react-router-dom";

const idleTime = 1000 * 60 * 14;
const promptTime = 1000 * 60;
//import {useTranslation} from "react-i18next";
let countDownId;

const IdleTimerComponent = ({children}) => {
    const dispatch = useDispatch();
    const idleTimerState = useSelector(selectIdleState);
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        if([homeUrl, startTestUrl].includes(location.pathname)) {
            dispatch(changeState({idleState: "active"}));
            pause();
        } else start();
    }, [location]);
    
    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        //console.log("onIdle", event);
        dispatch(changeState({idleState: "idle"}));
        if(countDownId) clearInterval(countDownId);
        console.log("onIdle CANCEL TEST");
        dispatch(cancelTest());
        history.push(homeUrl);
        };
    
        const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
        //console.log("onActive", event);
        dispatch(changeState({idleState: "active"}));
        };

        const onAction = (event) => {
            // Close Modal Prompt
            // Do some active action
            //console.log("onAction", idleTimerState);
            if(idleTimerState.idleState === "prompted") {
              reset();
              if(countDownId) clearInterval(countDownId);
              dispatch(changeState({idleState: "active"}));
            }
        };

        const onPrompt = (event) => {
        // Close Modal Prompt
        // Do some active action
        //console.log("onPrompt", event);
        dispatch(changeState({idleState: "prompted"}));
        countDownId = setInterval(() => {
            // eslint-disable-next-line no-use-before-define
            dispatch(setTimeleft({timeLeft: getRemainingTime()}));
        }, 1000);

            
        };

        
    const {start, pause, reset, getRemainingTime} = useIdleTimer({ onIdle, onActive, onAction, onPrompt, crossTab: true, timers: workerTimers, timeout: idleTime, promptTimeout: promptTime });
    

    return (
        <>{children}</>
    );
};


export const IdleTimer = IdleTimerComponent;
