import { MESSAGE_TYPE, addItem } from '/redux/slices/snackbarSlice';

import React from 'react';
import {cancelTest} from '/redux/slices/directorSlice';
import {startTestUrl} from '/routes/routesUrls';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const ErrorComponent = (error) => {

    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const doCancelTest = () => {

        dispatch(cancelTest({}));
        dispatch(addItem({text: t("error.systemReseted.message"), type: MESSAGE_TYPE.INFO, props: {}}));
        history.push(startTestUrl);

    };

    return (
        <>
            <div dangerouslySetInnerHTML={
                {__html: t("error.errorHTML")}
            }></div>
            <code>{
                JSON.stringify(error)
            }</code>
            <button onClick={doCancelTest} className="button is-primary button--direction-right is-rounded">
                <span className="button__text">{t("error.restartButton.caption")}</span>
                <span className="icon licon-arrow-circle-right"></span>
            </button>

        </>
    );
};

export const Error = ErrorComponent;
