import ParentField from "./parentField";
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {connectField} from 'uniforms';

const Text = ({
                autoComplete,
                disabled,
                id,
                inputRef,
                label,
                name,
                onChange,
                placeholder,
                readOnly,
                type,
                value,
                inputClassName,
                error,
                description,
                fieldInfo,
                ...props
              }) => {

  return (
    <ParentField
      id={id}
      label={label}
      description={description}
      fieldInfo={fieldInfo}
    >
      <input
        autoComplete={autoComplete}
        disabled={disabled}
        id={id}
        name={name}
        onChange={event => onChange(event.target.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={inputRef}
        type={type}
        value={value ?? ''}
        className={classNames(inputClassName, {invalid: error && props.showInlineError})}
      />
    </ParentField>
  );
};

Text.propTypes = {
  autoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.any,
  inputRef: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
  inputClassName: PropTypes.string,
  error: PropTypes.any,
  description: PropTypes.string,
  fieldInfo: PropTypes.string,
  showInlineError: PropTypes.bool
};

Text.defaultProps = {type: 'text', value: ''};

export default connectField(Text, {kind: 'leaf'});
