import PropTypes from "prop-types";
import React from "react";
import classNames from 'classnames';
//import {useTranslation} from "react-i18next";

const GroupNavigation = ({noOfGroups, currentGroupNo, answeredGroups}) => {

    //console.log("GroupNavigation", noOfGroups, currentGroupNo, answeredGroups);

    const groupClasses = [
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten"
    ];

    //const {t} = useTranslation();
    const renderElements = () => {

        let items = [];
        for (let i = 1; i <= noOfGroups; i++) {

            let classes = {
                "qanda__bubble": true,
                "qanda__bubble--answered": answeredGroups.includes(i),
                "qanda__bubble--active": currentGroupNo === i
            };
            classes["qanda__bubble-" + 1] = true;
            items.push(
                <span key={i} className={classNames(classes)}>
                    <span>{i}</span>
                    </span>
            );

        }
        return items;
    };

    return (
        <div className="qanda__bubbles">
            { renderElements()}
        </div>
    );
};

GroupNavigation.propTypes = {
    noOfGroups: PropTypes.number,
    currentGroupNo: PropTypes.number,
    answeredGroups: PropTypes.array
};

export default GroupNavigation;
