import * as ioCalls from '/lib/io';

import { MESSAGE_TYPE, addItem } from '/redux/slices/snackbarSlice';
import { setStoreState, selectDirector } from '/redux/slices/directorSlice';
import { useDispatch, useSelector } from 'react-redux';

import React from "react";
import { deactivateModal, selectModal, hideModal, unhideModal } from '/redux/slices/modalSlice';
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { scrollToId } from '/lib/helper';
import { testResultUrl } from '/routes/routesUrls';

const Component = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const directorState = useSelector(selectDirector);
    const modalState = useSelector(selectModal);

    // React.useEffect(() => {
    //     //console.log("xxx", modalState);
    //     if(modalState.modalActive) document.body.classList.add('test--out');
    //     else document.body.classList.remove('test--out');

    // }, [modalState.modalActive]);

    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    //   };

    const removeModal = () => {
        dispatch(unhideModal({}));
        dispatch(deactivateModal({}));
    };

    const goNextSection = async () => {
        //console.log("goNextSection", modalState.modalPayload);
        let res;
        const isLastSection = directorState.currentSectionNo === directorState.noOfSections;
        dispatch(hideModal({}));
        //await sleep(5000);
        const succF = (res) => {
            //console.log("NextSection", "onSubmitSuccess", res.error, res);
            scrollToId("story__top");
            document.body.classList.remove('test--out');
            removeModal();
            if (isLastSection) history.push(testResultUrl);
            else dispatch(setStoreState({ section: res }));
        };
        const onSubmitFailure = (res) => {
            //console.log("NextSection", "onSubmitFailure", res.error, res);
            document.body.classList.remove('test--out');
            dispatch(unhideModal({}));
            dispatch(addItem({ text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {} }));
        };
        document.body.classList.add('test--out');
        if (isLastSection) res = ioCalls.completeTest(directorState.sessionId, modalState.modalPayload.model);
        else res = ioCalls.getNextSection(directorState.sessionId, modalState.modalPayload.model);
        res.then(succF, onSubmitFailure);
        return res;

    };
    //Todo: translate
    return (
        <>
            {/* <header className="modal-card-head">
                <p className="modal-card-title">Zum nächsten Abschnitt</p>
            </header> */}

            <section className="modal-card-body">
                <p>
                    {t("modal.nextSection.content")}
                </p>
                <div className="mt-25">
                    <button onClick={goNextSection} className="button is-success button--direction-down is-rounded">
                        <span className="icon licon-arrow-circle-down"></span>
                        <span className="button__text">{t("modal.nextSection.submitButton.caption")}</span>
                    </button>
                    <button  onClick={removeModal} className="button is-text is-rounded">
                        <span className="icon licon-x"></span>
                        <span className="button__text">{t("modal.nextSection.cancelButton.caption")}</span>
                    </button>
                </div>
            </section>
            
        </>
    );
};

export const NextSection = Component;