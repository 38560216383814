import 'regenerator-runtime/runtime'; // to use async function in parcel

//import testResponses from "./ioTestData";
import i18n from "i18next";
//import log from 'loglevel';
import { setSubmitting } from '/redux/slices/directorSlice';
import {store} from '/redux/store';

const backendHost = window.location.host.includes("localhost") ? "http://localhost:8000/api" : "https://api.lerche-lesediagnose.at";

export const pdfUrl = backendHost + '/pdfReport?session_id=';

const apiEndPoints = {
    previousGroup: backendHost + '/previousGroup',
    answersGroup: backendHost + '/answersGroup',
    startTest: backendHost + '/startTest',
    resumeTest: backendHost + '/resumeTest',
    nextSection: backendHost + '/nextSection',
    abortTest: backendHost + '/abortTest',
    textResults: backendHost + '/textResults'
};

const defaultOptions = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
    }
};


// eslint-disable-next-line no-unused-vars
const fetchApi = async (url, payload = {}, options = {}) => {

    let finalUrl = url;

    let defOptions = Object.assign({}, defaultOptions, options);
    defOptions.headers['Content-Language'] = i18n.language.substring(0, 2);
    defOptions.headers['Accept-Language'] = i18n.language.substring(0, 2);
    //log.info("fetchApi", "defOptions #1", defOptions);

    if (options?.method && options.method === "GET") {
        finalUrl += '?' + (new URLSearchParams(payload)).toString();
    } else {
        defOptions.body = JSON.stringify(payload);
    }


    //log.info("x001 fetchApi", "defOptions #2", defOptions);
    store.dispatch(setSubmitting({ submitting: true }));
    const response = await fetch(finalUrl, defOptions)
        .then((response) => {
            return [204].includes(response.status) ? {} : response.json();
        })
        .catch(function (error) {
            return { error: error.error || 'error.connectingToServer', payload: {url, payload, error} };
        }).finally(() => {
            store.dispatch(setSubmitting({ submitting: false }));
        });
    
    if(response.error) return { error: response.error || 'error.connectingToServer', payload: {url, payload, error: response.error} };
    return response;
};

export const startTest = async (sessionId) => { //session
    let res = await fetchApi(apiEndPoints.startTest, { concrete_test_code: sessionId }, {method: "POST"}); //
    return res;
};

export const resumeTest = async (sessionId) => { 
    return await fetchApi(apiEndPoints.resumeTest, { session_id: sessionId}, {method: "GET"});
};

export const getPreviousGroup = async (sessionId, answers) => { 
    return await fetchApi(apiEndPoints.previousGroup, { session_id: sessionId, student_answer: answers }, {method: "POST"});
};

export const getNextSection = async (sessionId, answers) => { 
    return await fetchApi(apiEndPoints.nextSection, { session_id: sessionId, student_answer: answers }, {method: "POST"});
};

export const getStudentResults = async (sessionId) => { 
    return await fetchApi(apiEndPoints.textResults, { session_id: sessionId, type: "student" }, {method: "GET"});
};

export const getTeacherResults = async (sessionId) => { 
    return await fetchApi(apiEndPoints.textResults, { session_id: sessionId, type: "teacher"  }, {method: "GET"});
};


export const abortTest = async (sessionId) => { 
    return await fetchApi(apiEndPoints.abortTest, { session_id: sessionId}, {method: "PUT"});
};


export const submitAnswers = async (sessionId, answers) => { 
    return await fetchApi(apiEndPoints.answersGroup, { session_id: sessionId, student_answer: answers }, {method: "POST"});
};

export const completeTest = async (sessionId, answers) => { 
    return await fetchApi(apiEndPoints.answersGroup, { session_id: sessionId, complete_test: true, student_answer: answers }, {method: "POST"});
};