import ParentField from "./parentField";
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {connectField} from 'uniforms';
import {useTranslation} from "react-i18next";

const Component = ({
                       disabled,
                       id,
                       label,
                       onChange,
                       //readOnly,
                       //type,
                       value,
                       cardClassName,
                       //error,
                       description,
                       fieldInfo,
                       years,
                       ...props
                   }) => {


    //const {t} = useTranslation();
    const [currentMonth, setCurrentMonth] = useState();
    const [currentYear, setCurrentYear] = useState();

    useEffect(() => {
        if (value) {
            const currentDate = new Date(value);
            setCurrentMonth(currentDate.getMonth() + 1);
            setCurrentYear(currentDate.getFullYear());
        }
    }, [value]);
    //console.log("Component", value, currentMonth, currentYear);
    const isActive = value !== undefined && value !== "";

    const onChangeSelect = (event, type) => {

        //console.log("onChangeSelect", event.target.value, type);
        const newVal = Number(event.target.value);
        switch (type) {
            case 'student_birthmonth':
                setCurrentMonth(newVal);

                if (currentYear) {
                    const newValue = new Date(currentYear, newVal - 1, 2, 0, 0, 0, 0).toISOString();
                    onChange(newValue);
                }
                break;
            case 'student_birthyear':
                setCurrentYear(newVal);

                if (currentMonth) {
                    const newValue = new Date(newVal, currentMonth - 1, 2, 0, 0, 0, 0).toISOString();
                    onChange(newValue);
                }
                break;
            default:

        }
    };

    return (
        <ParentField id={id}
                     label={label}
                     description={description}
                     fieldInfo={fieldInfo}
                     cardClassName={
                         "qanda__birthday " + cardClassName
                     }
                     active={isActive}
                     {...props}>
            <div className="qanda__answers">
                <label className={
                    classNames("qanda__birthmonthyear", {"qanda__qanda__birthmonthyear--active": isActive})
                }>

                    <SelectFieldElement name="student_birthmonth"
                                        onChange={onChangeSelect}
                                        disabled={disabled}
                                        currentValue={currentMonth}
                                        translateCaption={true}
                                        values={
                                            [
                                                1,
                                                2,
                                                3,
                                                4,
                                                5,
                                                6,
                                                7,
                                                8,
                                                9,
                                                10,
                                                11,
                                                12
                                            ]
                                        }/>

                </label>

                <label className={
                    classNames("qanda__monthyear", {"qanda__qanda__monthyear--active": isActive})
                }>
                    <SelectFieldElement name="student_birthyear"
                                        onChange={onChangeSelect}
                                        translateCaption={false}
                                        disabled={disabled}
                                        currentValue={currentYear}
                                        values={
                                            years
                                        }/>

                </label>
            </div>
        </ParentField>
    );
};

Component.propTypes = {
    autoComplete: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.any,
    inputRef: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
    cardClassName: PropTypes.string,
    error: PropTypes.any,
    description: PropTypes.string,
    fieldInfo: PropTypes.string,
    years: PropTypes.array,
    showInlineError: PropTypes.bool
};

Component.defaultProps = {
    type: 'text',
    years: [
        2006,
        2007,
        2008,
        2009,
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016
    ]
};

const SelectFieldElement = ({
                                values,
                                name,
                                disabled,
                                currentValue,
                                translateCaption,
                                onChange
                            }) => {

    const {t} = useTranslation();
    return (


        <div className="select">
            <select disabled={disabled}
                    onChange={
                        (e) => onChange(e, name)
                    }
                    name={name}
                    defaultValue={currentValue || t("yearMonthField_" + name + "_caption")}>
                <option disabled>
                    {
                        t("yearMonthField_" + name + "_caption")
                    }</option>
                {
                    values.map(aValue => {
                        return <option key={aValue}
                                       value={aValue}>
                            {
                                !translateCaption ? aValue : t("yearMonthField_" + name + "_" + aValue)
                            }</option>;
                    })
                } </select>
        </div>

    );

};


SelectFieldElement.propTypes = {
    values: PropTypes.array,
    name: PropTypes.string,
    currentValue: PropTypes.number,
    disabled: PropTypes.bool,
    translateCaption: PropTypes.bool,
    onChange: PropTypes.func
};


export default connectField(Component, {kind: 'leaf'});
