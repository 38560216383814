import { homeUrl, impressUrl, notFoundUrl, privacyPolicyUrl, startTestUrl, testResultUrl, testResultUrlWithTypeAndSession, testUrl } from "/routes/routesUrls";

import {CustomRoute} from "./customRoute";
import { DIRECTOR_STEPS } from '/redux/slices/directorSlice';
import HomePage from "/pages/home/HomePage";
import ImpressPage from "../pages/more/ImpressPage";
import NotFoundPage from "/pages/error/NotFoundPage";
import PrivacyPolicy from "../pages/more/PrivacyPolicy";
import React from "react";
import StartTestPage from '/pages/test/StartTest';
import { Switch } from 'react-router-dom';
import TestPage from '/pages/test/TestPage';
import TestResultPage from '/pages/test/TestResult';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';

export const Routes = () => {
    return (
        <Switch>
            <CustomRoute exact path={testUrl} component={TestPage}/>
            <CustomRoute exact path={startTestUrl} component={StartTestPage}/>
            <CustomRoute exact path={testResultUrl} component={TestResultPage}/>
            <CustomRoute exact path={testResultUrlWithTypeAndSession} component={TestResultPage}/>
            <CustomRoute exact path={homeUrl} component={HomePage}/>
            <CustomRoute exact path={impressUrl} component={ImpressPage} />
            <CustomRoute exact path={privacyPolicyUrl} component={PrivacyPolicy} />
            <CustomRoute path={notFoundUrl} component={NotFoundPage} />
        </Switch>
    );
};


export const autoRoute = (directorState, history) => {
    //log.info("create autoRoute", directorState.sessionId, directorState.currentStep);
    return () => {
        
        if(directorState.sessionId) {
            if(directorState.currentStep === DIRECTOR_STEPS.FINISHED) {
                //log.info("autoRoute to testResultUrl");
                history.push(testResultUrl);
                return;
            } else {
                //log.info("autoRoute to testUrl");
                history.push(testUrl);
                return;
            }
        }
    };
};