import { createSlice } from '@reduxjs/toolkit';
import { hashCode } from '/lib/helper';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';

const initialSnackbarSliceState = {
    items: []
};

export const TIMEOUT = 10500;
export const MESSAGE_TYPE = {
    ERROR: "ERROR",
    INFO: "INFO",
    SUCCESS: "SUCCESS"
};

const snackbarSlice = createSlice({
    name: 'snackbarSlice',
    initialState: initialSnackbarSliceState,
    reducers: {
        // removeOldItems(state, action) {
        //     console.log("removeSnackbar", state, action.payload);
        //     const now = new Date();
        //     state.items = state.items.filter(aBar => aBar.timeout < now);
        // },
        removeItem(state, action) {
            //console.log("removeSnackbar", state, action.payload);
            const { id } = action.payload;
            state.items = state.items.filter(aBar => aBar.id !== id);
        },
        addItem(state, action) {
            //console.log("addSnackbar", action.payload);
            const { text, type, props } = action.payload;
            const hash = hashCode(text);
            state.items.push({ text, type, props, id: hash, timeout: new Date(new Date().getTime() + 1000).toISOString() });
        }
    }
});

export const selectSnackbar = (state) => state.snackbarReducer;

export const {
    addItem,
    //removeOldItems,
    removeItem
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
