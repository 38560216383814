import {deactivateModal, selectModal} from '/redux/slices/modalSlice';
import {useDispatch, useSelector} from 'react-redux';

import React from 'react';
import classNames from 'classnames';
//import log from 'loglevel';
import {modals} from '/modals';
import {useLocation} from 'react-router-dom';

//import {useTranslation} from "react-i18next";

const ModalComponent = () => {
    const modalState = useSelector(selectModal);
    //const history = useHistory();
    //const {t} = useTranslation();
    const dispatch = useDispatch();
    // console.log("ModalComponent", history.location.pathname, testUrl);

    const location = useLocation();
    React.useEffect(() => {}, [location]);

    const removeModal = () => {
        dispatch(deactivateModal({}));
    };

    //log.info("ModalComponent", modalState.modalContent, modals);

    return (
        <div className={classNames("modal", {"is-active": modalState.modalActive && !modalState.modalHidden})}>

            <div className="modal-background"></div>

            <div className="modal-card">

                {modalState.modalContent && modals[modalState.modalContent] && React.createElement(modals[modalState.modalContent], {})}

            </div>

            <button onClick={removeModal} className="button is-tertiary modal--close" aria-label="close">
                <span className="icon licon-x"></span>
            </button>

        </div>
    );
};


export const Modal = ModalComponent;
