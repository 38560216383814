import React from "react";
import { deactivateModal } from '/redux/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

const HelpComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const removeModal = () => {
        console.log("removeModal");
        dispatch(deactivateModal({}));
    };


    return (
        <>
            <header className="modal-card-head">
                <p className="modal-card-title">{t("modal.help.caption")}</p>
            </header>

            <section className="modal-card-body" dangerouslySetInnerHTML={{__html: t("modal.help.content")}}></section>

            <footer className="modal-card-foot">
                <button onClick={removeModal} className="button is-text is-rounded">
                    <span className="icon licon-x"></span>
                    <span className="button__text">Schließen</span>
                </button>
            </footer>
        </>
    );
};

export const Help = HelpComponent;