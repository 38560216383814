import { setupSchemaFromJson } from './uniformsHelper';

// const createTestDefinition = {
//     type: "object",
//     properties: {
//         email: {
//             type: 'string',
//             //pattern: "/^[a-z0-9\._%+!$&*=^|~#%{}\\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,22})$",
//             format: "email",
//             title: "Email",
//             description: "get Email",
//             uniforms: {
//                 component: InputField,
//                 inputClassName: "full-width"
//             }

//         }
//     }
// };


// const editTestDefinition = {
//     type: "object",
//     properties: {
//         testId: {
//             type: 'string',
//             title: "Test type",
//             description: "Select test",
//             uniforms: {
//                 allowedValues: ["Test A", "Test B"],
//                 component: SelectField
//                 //inputClassName: "full-width"
//             }

//         },
//         active: {
//             type: 'boolean',
//             title: "Active",
//             description: "is Test acitve",
//             uniforms: {
//                 component: BoolField
//                 //inputClassName: "full-width"
//             }

//         }
//     }
// };

const startTestDefinition = JSON.stringify({
    type: "object",
    properties: {
        testOrSessionId: {
            type: 'string',
            minLength: 0,
            maxLength: 1000,
            title: "Test-Code",
            uniforms: {
                placeholder: "Test-Code eingeben"
            },
            fieldType: "TESTCODE_FIELD"
        }
    }
});

export const startTestSchema = setupSchemaFromJson(startTestDefinition);