import * as ioCalls from '/lib/io';

import {AutoFields, AutoForm} from "uniforms-unstyled";
import {MESSAGE_TYPE, addItem} from '/redux/slices/snackbarSlice';
import React, {useEffect} from "react";
import {activateModal} from '/redux/slices/modalSlice';
import {cancelTest, selectDirector, setStoreState, textRaed} from '/redux/slices/directorSlice';

import GroupNavigation from "/components/test/groupNavigation";
import QandaButton from "/components/forms/qandaButton";
import SectionProgress from "/components/test/sectionProgress";
import ScrollArea from "/components/test/scrollArea";
import {autoRoute} from "/routes/routes";
import classNames from 'classnames';
// eslint-disable-next-line no-unused-vars
import log from 'loglevel';
import {scrollToId} from '/lib/helper';
import {setupSchemaFromJson} from "/modules/uniforms/uniformsHelper";
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import ReactTooltip from 'react-tooltip';
import {startTestUrl} from "/routes/routesUrls";


const TestPage = () => {
    const directorState = useSelector(selectDirector);
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    let formRef;
    useEffect(autoRoute(directorState, history), [directorState]);

    useEffect(() => {
        if (directorState?.sessionId) {
            ioCalls.resumeTest(directorState.sessionId)
                .then(res => {
                    if (res?.error.includes('Can\'t find the session.') || res?.error.includes('Die Session wurde nicht gefunden.')) {
                        dispatch(cancelTest({}));
                        history.push(startTestUrl);
                    }
                })
                .catch(err => {
                    if (err?.error.includes('Can\'t find the session.') || err?.error.includes('Die Session wurde nicht gefunden.')) {
                        dispatch(cancelTest({}));
                        history.push(startTestUrl);
                    }
                });
        }
    }, []);

    useEffect(() => {
        ReactTooltip.rebuild();
        document.body.classList.add('layout--test', 'test--in');
        return () => {
            document.body.classList.remove('layout--test', 'test--in');
        };

    }, []);

    const onSubmitSuccess = (res) => {
        //log.info("TestPage", "cbBackward", res.error, res);
        dispatch(setStoreState({section: res}));
    };

    const onSubmitFailure = (res) => {
        dispatch(addItem({text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {}}));
    };

    const cbForeward = (model) => {
        //log.info("x001 TestPage", "cbForeward", directorState.submitting, model);
        if (directorState.submitting) {
            //console.log("x001 cbForeward submitting");
            return;
        }
        const res = ioCalls.submitAnswers(directorState.sessionId, model);
        res.then(onSubmitSuccess, onSubmitFailure);
        return res;
    };

    const cbBackward = async (model) => {
        //log.info("x001 TestPage", "cbBackward", directorState.submitting, model);
        if (directorState.submitting) {
            //console.log("x001 cbBackward submitting");
            return;
        }
        const res = ioCalls.getPreviousGroup(directorState.sessionId, model);
        res.then(onSubmitSuccess, onSubmitFailure);
        return res;
    };


    const nextSection = async (model) => {

        if (directorState.submitting) {
            //console.log("x001 nextSection submitting");
            return;
        }
        dispatch(activateModal({content: "NextSection", model}));


    };

    // const finishTest = async (model) => {

    //     if (directorState.submitting) {
    //         //console.log("x001 nextSection submitting");
    //         //return;
    //     }
    //     const res = ioCalls.submitAnswers(directorState.sessionId, model);
    //     const succF = (res) => {
    //         if (!res.error) {
    //             history.push(testResultUrl);
    //         } else dispatch(addItem({ text: t(res.error), type: MESSAGE_TYPE.ERROR, props: {} }));
    //     };
    //     res.then(succF, onSubmitFailure);
    //     return res;


    // };

    const onClickCard = (selectedCardIndex) => {
        //console.log("xxx", directorState.currentGroupNo, selectedCardIndex);
        if (directorState.currentGroupNo - 1 > selectedCardIndex) cbBackward();
        if (directorState.currentGroupNo - 1 < selectedCardIndex) cbForeward();
    };


    const getForm = (uniformSchema) => {

        let nextSectionCaption = t("testPage.nextSectionButton.caption");
        let nextSectionInfo = t("testPage.answeredAllQuestions.text");
        // meta section
        if (directorState.currentSectionNo === 1) {
            nextSectionCaption = t("testPage.meta.nextSectionButton.caption");
            nextSectionInfo = t("testPage.answeredAllQuestionsMeta.text");
        }
        const showFinishTestButton = directorState.currentSectionNo === directorState.noOfSections;

        let introElement = null;
        if (directorState.description) {
            introElement = (
                <div className="qanda__intro">
                    <p className="qanda__intro__text">
                        {directorState.description}
                    </p>
                </div>
            );
        }

        //console.log("getForm /showFinishTestButton / directorState.readyForNextSection yyy01", showFinishTestButton, directorState.readyForNextSection);
        return (
            <>
                {directorState.noOfGroups > 1 &&
                    <GroupNavigation noOfGroups={directorState.noOfGroups} currentGroupNo={directorState.currentGroupNo}
                                     answeredGroups={directorState.answeredGroups}/>}
                <AutoForm
                    className=""
                    schema={uniformSchema}
                    placeholder
                    //onSubmit={handleSubmit}
                    validate="onChange"
                    //onChangeModel={model => setCurrentLayer(() => model.backgroundLayer)}
                    ref={ref => (formRef = ref)}
                >
                    <div className="qanda__cards" key="cards">
                        <div
                            className={"qanda__cards-holder is-multiple is-active-card-id-" + (directorState.currentGroupNo - 1)}>
                            {
                                [...Array(directorState.noOfGroups)].map((x, i) =>
                                    <div key={"card-" + i} onClick={onClickCard.bind(this, i)}
                                         className={classNames("card-id-" + i, "qanda__card", {"qanda__card--test": directorState.currentSectionNo !== 1}, {"qanda__card--meta": directorState.currentSectionNo === 1}, "qanda__card--active")}>
                                        {introElement}
                                        <div className="qanda__forms">
                                            {i === directorState.currentGroupNo - 1 && <AutoFields element="span"/>}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="qanda__controls" key="controls">

                        {directorState.noOfGroups !== 1 &&
                            <div className="qanda__backforward" key="foreward">
                                <QandaButton cb={cbBackward} dir={"before"} formRef={formRef}
                                             classes={["button--direction-left", "qanda__back", "qanda__back--active", directorState.currentGroupNo > 1 ? "" : "qanda__back--invisible"]}
                                             iconClasses={["licon-caret-left"]}
                                             caption={t("testPage.prevButton.caption")}/>

                                {directorState.noOfGroups !== directorState.currentGroupNo &&
                                    <QandaButton cb={cbForeward} dir={"after"} formRef={formRef}
                                                 classes={["button--direction-right", "is-primary", "qanda__forward", "qanda__forward--active", directorState.currentGroupNo < directorState.noOfGroups ? "" : "qanda__forward--invisible"]}
                                                 iconClasses={["licon-caret-right"]}
                                                 caption={t("testPage.nextButton.caption")}/>
                                }
                            </div>
                        }
                        {directorState.readyForNextSection &&
                            <div className="qanda__complete">
                                <p className="little-info">{nextSectionInfo}</p>
                                {directorState.currentSectionNo !== 1 && !showFinishTestButton &&
                                    <QandaButton cb={nextSection} dir="after" formRef={formRef}
                                                 classes={["button--direction-down", "is-success"]}
                                                 iconClasses={["licon-arrow-circle-down"]}
                                                 caption={nextSectionCaption}/>}
                                {directorState.currentSectionNo !== 1 && showFinishTestButton &&
                                    <QandaButton cb={nextSection} formRef={formRef} dir="after"
                                                 classes={["button--direction-right", "is-rounded", "is-success"]}
                                                 iconClasses={["licon-arrow-circle-right"]}
                                                 caption={t("testPage.finishTestButton.caption")}/>}

                                {directorState.currentSectionNo === 1 &&
                                    <QandaButton cb={nextSection} formRef={formRef} dir="after"
                                                 classes={["button--direction-down", "is-success"]}
                                                 iconClasses={["licon-arrow-circle-down"]}
                                                 caption={nextSectionCaption}/>

                                }

                            </div>
                        }


                    </div>

                </AutoForm>

            </>);
    };

    // const copySessionID = () => {
    //     copyToClipboard(directorState.sessionId);
    //     dispatch(addItem({ text: t("testPage.session-Code.copied"), type: MESSAGE_TYPE.INFO, props: {} }));
    // };

    const getIntro = (inForm = false, noIcon = false) => {

        let intro = [];
        let introText = t("testPage.default.section.intro");
        let className = "qanda__card--test";
        // eslint-disable-next-line react/jsx-key
        let iconElement = [<span key="icon-info" className="icon licon-info is-info"></span>];
        if (directorState.currentSectionNo === 1) {
            introText = t("testPage.meta.section.intro");
            className = "qanda__card--meta";
            iconElement = [];
        } else {
            //if (inForm) return []; // there is no info in test groups
        }

        intro.push(<div className="qanda__intro" key="intro">
            {!noIcon && iconElement}
            <p className="qanda__intro__text is-info">
                {introText}
            </p>
        </div>);

        if (inForm) return intro;
        return <div className="qanda__cards">
            <div className="qanda__cards-holder">
                <div className={classNames("qanda__card", "qanda__card--active", className)}>
                    {intro}
                </div>
            </div>
        </div>;
    };

    const triggerRead = () => {
        //console.log("triggerRead");
        dispatch(textRaed({}));
        scrollToId("story__top");
    };


    const getTestForm = (jsonSchema) => {
        const uniformSchema = setupSchemaFromJson(jsonSchema);
        //log.info("TestPage", "getTestForm", jsonSchema, uniformSchema);
        //console.log("getTestForm yyy01");
        return (
            <>
                <>
                    <div className="layout__left">

                        {!directorState.text && <div className="metabg">
                            <span key="icon-bird" className="icon licon-bird metabg__icon"></span>
                            <span key="text" className="metabg__text">
                                <span>zwitscher</span>
				                <span>zwitscher</span>
                            </span>

                        </div>}


                        {/* <div className="story__image-left is-hidden">
                            <img src="public/img/iguana.png" />
                        </div> */}

                        {directorState.text && <div className="story">
                            <ScrollArea>
                                <a id="story__top"></a>

                                {/* <div className="story__image-top is-hidden">
                                    <img src="public/img/bird.png" />
                                </div> */}

                                <div className="story__header">
                                    <h2 className="story__title">{directorState.title}</h2>
                                    {directorState.description &&
                                        <p className="little-info">{directorState.description}</p>}
                                </div>
                                <div className="story__text">
                                    {/* <div className="story__image-float is-hidden">
                                        <img src="public/img/bird.png" />
                                    </div> */}
                                    <p dangerouslySetInnerHTML={{__html: directorState.text}}></p>
                                </div>

                                {!directorState.textRaed &&
                                    <div
                                        className={classNames("story__complete", {"story__complete--invisible": directorState.textRaed})}>
                                        <p className="little-info">{t("testPage.textRaedButton.info")}
                                            <span key="trigger" className="helper-trigger">
                                            <a onClick={() => {
                                                dispatch(activateModal({content: "Help"}));
                                            }} className="icon licon-info is-info" title="Tooltipp und/oder Modal"></a>
                                        </span>
                                        </p>
                                        <button onClick={triggerRead}
                                                className="button button--direction-right is-rounded is-primary">
                                            <span key="text"
                                                  className="button__text">{t("testPage.textRaedButton.caption")}</span>
                                            <span key="icon" className="icon licon-arrow-circle-right"></span>
                                        </button>
                                    </div>
                                }
                            </ScrollArea>
                        </div>}
                    </div>


                    <div className="layout__right">
                        <div className="qanda">
                            <div className="qanda__header">
                                <p className="qanda__header__item">
                                    <span className="qanda__header--title">Test-Code: {directorState.sessionId}</span>
                                </p>
                            </div>
                            {<SectionProgress noOfSections={directorState.noOfSections + 1}
                                              currentSectionNo={directorState.currentSectionNo}/>}

                            {directorState.textRaed && uniformSchema && getForm(uniformSchema)}

                            {!directorState.textRaed && getIntro(false, true)}

                        </div>
                    </div>
                </>

            </>
        );
    };
    //log.info("TestPage", directorState.jsonSchema);
    //console.log("render yyy01");
    return (
        <>
            {directorState.jsonSchema && getTestForm(directorState.jsonSchema)}
        </>
    );
};

export default TestPage;
